import style from "./bmsTickets.module.css";
import { TextField, Modal, Box, Grid, Button, Typography } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import Api from "../../api";
import "quill/dist/quill.core.css";
import UserContext from "../../context/userContext";
import SendIcon from "@mui/icons-material/Send";
import QuillEditor from "../../components/QuillEditor";
import LinearProgress from '@mui/material/LinearProgress';
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

let data_content = "Slik logger du inn p\u00e5 Microsoft 365:\n\n1. G\u00e5 til nettsiden https://www.office.com\n2. Klikk p\u00e5 \"Logg p\u00e5\".\n3. Skriv inn e-postadressen og klikk \"Neste\".\n4. Tast inn passordet ditt og klikk \"Logg p\u00e5\".\n5. Fullf\u00f8r eventuell tofaktorautentisering hvis du blir bedt om det.\n\nSend inn sak hvis du trenger mer hjelp";

export default function TicketModal(props: any) {
    const [title, setTitle] = useState("");
    const [editorValue, setEditorValue] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAI, setIsLoadingAI] = useState(false);
    const [AIResponse, setAIResponse] = useState("");  

    const { setSnackbarText } = useContext(UserContext);

    useEffect(() => {
        setAIResponse("")
    }, [props.show])

    useEffect(() => {
        setErrorMsg("");
    }, [title, editorValue]);

    const handleEditorChange = (value) => {
        setEditorValue(value);
    };

    function resetForm() {
        setTitle("");
        setEditorValue("");
    }

    async function submitToAI() {
        setAIResponse("");

        if (title?.length === 0 && editorValue?.length === 0) {
            setErrorMsg("Minst ett av feltene må være utfylt");
            return;
        }

        const content = new DOMParser().parseFromString(editorValue, "text/html");

        const data = {
            title: title,
            content: content.body.textContent || "",
        };

        setIsLoadingAI(true)

        try {
            const response = await Api.post("/chat", data);
            setAIResponse(response["result"])
            
        } catch (e) {
            setSnackbarText("Noe gikk galt");
        } finally{
            setIsLoadingAI(false)
        }
    }

    async function submitForm() {
        if (title?.length === 0) {
            setErrorMsg("Emnefeltet kan ikke være tomt");
            return;
        }
        if (editorValue?.length === 0) {
            setErrorMsg("Innholdsfeltet kan ikke være tomt");
            return;
        }

        const data = {
            title: title,
            content: editorValue,
        };
        setIsLoading(true)

        try {
            await Api.post("/tickets", data);
            setSnackbarText("Saken er opprettet");
        } catch (e) {
            setSnackbarText("Noe gikk galt");
        }finally{
            setIsLoading(false)
            props.closeModal(true);
        }
        resetForm();
    }

    useEffect(() => {
        setEditorValue(props.initContentHtml);
    }, [props?.initContentHtml]);

    useEffect(() => {
        if (!props.show) {
            resetForm();
        }
    }, [props.show]);

    return (
        <div>
            <Modal
                open={props.show}
                onClose={() => {
                    props.closeModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={style.modal}>
                    <Typography variant="h5">Send inn sak</Typography>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            label="Emne"
                            className="mt-2"
                            variant="outlined"
                            size="small"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <QuillEditor
                            value={editorValue}
                            initValue={props.initContentHtml}
                            onChange={handleEditorChange}
                        />
                    </Box>

                    <Box sx={{ mt: 2,  display:"flex", justifyContent:"space-between"}}>
                        <Button onClick={submitToAI} disabled={isLoadingAI} startIcon={<SupportAgentIcon />} >Spør AI</Button>
                        <Button onClick={submitForm} disabled={isLoading} endIcon={<SendIcon />} >Send inn</Button>
                    </Box>


                    {(isLoadingAI || AIResponse) && <Box sx={{mt:"1rem"}}>
                        {isLoadingAI && <LinearProgress />}
                        {AIResponse && <Typography variant="body2" sx={{borderLeft:"0.3rem lightgrey solid", padding:"0.5rem"}}>
                            <div style={{ whiteSpace: "pre-line"}}>
                        {AIResponse}
                        </div>
                    </Typography>}
                    </Box>}
                    {errorMsg && (
                        <p>
                            <Typography variant="caption" color="red">
                                {errorMsg}
                            </Typography>
                        </p>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
