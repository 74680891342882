import {
    Grid,
    Typography,
    TextField,
    Tabs,
    Tab,
    Button,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Container,
    Box,
    InputAdornment,
} from "@mui/material";
import Api from "../../api";
import style from "./dashboard.module.css";
import { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TerminalIcon from "@mui/icons-material/Terminal";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
    ITicket,
    IDashboard,
    ILicenseAssignment,
    IPost,
} from "../../interface";
import TicketModal from "../tickets/TicketSubmitModal";
import AddIcon from "@mui/icons-material/Add";
import { TicketViewer } from "../tickets/ticketViewer";
import IconWithText from "../../components/IconWithText";
import { DeviceTable } from "../devices/Devices";
import UserContext from "../../context/userContext";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

export async function dashboardLoader() {
    return Api.get("/dashboard");
}

const NewsGrid = (props: any) => (
    <Swiper
        slidesPerView={4}
        spaceBetween={20}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Pagination, Autoplay]}
        className="swiperSlideshow"
        navigation={true}
        pagination={{
            clickable: true,
        }}
        style={{padding:"1rem 0 3rem 0"}}
    >
        {props.posts?.map((news) => (
            <SwiperSlide>
                <Card sx={{ margin:"0 0.2rem 0 0.2rem" }}>
                    <CardMedia
                        sx={{ height: "10rem", display:"flex", justifyContent:"center", alignItems:"center"  }}
                        title="icon"
                    >
                        <img src={news.image} style={{height:"7rem", }} alt="news_img"/>
                        </CardMedia>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        {news.header}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                        >
                            {news.content}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" href={news.url} target="_blank">Les mer</Button>
                    </CardActions>
                </Card>
            </SwiperSlide>
        ))}
    </Swiper>
);
/*
<Grid container spacing={4} style={{marginTop:"0rem"}}>
        {props.posts?.map((news) => <NewsItem key={news.header}  {...news}/>)}
    </Grid>
*/

const NewsItem = (props: IPost) => <div></div>;

const ServiceItem = (props: any) => (
    <Grid container spacing={2} alignItems="center">
        <Grid item>
            <img src={props.img} style={{ maxWidth: "4em" }} alt="news_img" />
        </Grid>
        <Grid item>
            <Typography variant="h6">{props.header}</Typography>
            <Typography variant="subtitle2">{props.content}</Typography>
            <Button
                sx={{ padding: "0.3rem 0 0 0" }}
                endIcon={<SendIcon />}
                target="_blank"
                href={props.url}
                disabled={props.disabled}
            >
                {props.header}
            </Button>
        </Grid>
    </Grid>
);

export function Tile(props) {
    const navigate = useNavigate();

    return (
        <Grid item md={3}>
            <div className={style.tileWrapper}>
                <div className={style.tileheader}>
                    <Typography variant="body1">{props.title}</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                        {props.desc}
                    </Typography>
                </div>
                <div className={style.tilecontent}>
                    <Typography variant="h5" sx={{ marginTop: "0.5rem" }}>
                        {props.value}
                    </Typography>
                    {props.action && (
                        <Button
                            onClick={() => {
                                props.action.link
                                    ? navigate(props.action.link)
                                    : props?.action?.click();
                            }}
                        >
                            {props.action.label}
                        </Button>
                    )}
                </div>
            </div>
        </Grid>
    );
}

export function LicenseViewer(props: { result: ILicenseAssignment[] }) {
    if (props.result?.length === 0) {
        return (
            <div className={"iconPlaceholder"}>
                <TerminalIcon sx={{ transform: "scale(2)" }} />
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                    Ingen lisenser
                </Typography>
            </div>
        );
    }

    return (
        <>
            <Grid container justifyContent={"center"}>
                <Grid item sx={{ marginTop: "2rem" }} md={3}>
                    <Typography variant="body2">
                        Du har følgende Microsoft lisenser
                    </Typography>
                    <div className="separator"></div>

                    {props.result?.map((lis) => (
                        <ListItem key={lis.license.name}>
                            <ListItemAvatar>
                                <Avatar>
                                    <TerminalIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                color="red"
                                primary={
                                    lis.license.name ?? lis.license.part_number
                                }
                            />
                        </ListItem>
                    ))}
                </Grid>
            </Grid>
        </>
    );
}

export function ChatViewer(props) {
    const [chatInput, setChatInput] = useState("");
    const [chatLoading, setChatLoading] = useState(true);
    const [chatHistory, setChatHistory] = useState(null);

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                const data = await Api.get("/chat");
                setChatHistory(data?.result);
            } catch (e) {}
            setChatLoading(false);
        };
        fetchChatHistory();
    }, []);

    async function sendChatMessage() {
        setChatLoading(true);
        try {
            const data = await Api.post("/chat", { chat_message: chatInput });
            setChatHistory(data.result);
        } catch (e) {
            console.log(e);
        }
        setChatLoading(false);
        setChatInput("");
    }

    async function handleKeyPress(event) {
        if (event.key === "Enter") {
            if (chatInput && chatInput.length > 0) {
                sendChatMessage();
            }
            event.preventDefault();
        }
    }

    async function deleteChatHistory() {
        try {
            await Api.delete("/chat");
            setChatHistory(null);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const element = document.getElementById("chatContainer");
        element.scrollTo(0, element.scrollHeight);
    }, [chatHistory]);

    async function transformChatToBmscase() {
        let quoteHtmlString = chatHistory
            ?.map((msgObj, i) =>
                i > 0
                    ? `<blockquote><b>${msgObj.role === "user" ? "Du skrev" : "IT.no"}: </b> ${msgObj.content}</blockquote>`
                    : "",
            )
            .join("");
        quoteHtmlString = "<p>Fra chatten</p>" + quoteHtmlString + "<p></p>";

        props.setInitBmsHtml(quoteHtmlString);
        // setShowBmsModal(true);
    }

    return (
        <>
            <div className={style.chatBotWrapper}>
                <div style={{ position: "relative", width: "100%" }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        sx={{ paddingRight: "1rem", height: "3rem" }}
                    >
                        <Grid item></Grid>
                        <Grid item>
                            {chatHistory?.length > 0 && (
                                <Button
                                    startIcon={<DeleteIcon />}
                                    variant="text"
                                    color="warning"
                                    size="small"
                                    onClick={() => deleteChatHistory()}
                                >
                                    Slett historikk
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <div className={style.chatPlaceholder}>
                        {(chatHistory === null || chatHistory?.length < 2) && (
                            <>
                                <SupportAgentIcon
                                    sx={{ transform: "scale(2)" }}
                                />
                                <Typography
                                    variant="h6"
                                    sx={{ marginTop: "1rem" }}
                                >
                                    Spør vår Chatbot
                                </Typography>
                            </>
                        )}
                    </div>

                    <div id="chatContainer" className={style.chatSequence}>
                        {chatHistory?.map((chat, index) => {
                            if (index === 0) return <></>;
                            return (
                                <div key={index}>
                                    <div className={style.chatAuthor}>
                                        <b>
                                            {chat.role === "user"
                                                ? "Du skrev"
                                                : "IT.no"}
                                        </b>
                                    </div>
                                    <Typography variant="body1">
                                        {chat.content}
                                    </Typography>
                                </div>
                            );
                        })}
                        {chatHistory?.length > 1 && (
                            <div className={style.chatCreateCase}>
                                <Button
                                    startIcon={<AddIcon />}
                                    size="small"
                                    variant="text"
                                    onClick={transformChatToBmscase}
                                >
                                    Lag supportsak med chathistorikk
                                </Button>
                                <br />
                            </div>
                        )}
                    </div>
                    <div className={style.chatbotInput}>
                        <TextField
                            fullWidth
                            disabled={chatLoading}
                            InputProps={{
                                endAdornment: chatLoading ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={sendChatMessage}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                ),
                            }}
                            label="Skriv her"
                            variant="outlined"
                            value={chatInput}
                            onKeyDown={handleKeyPress}
                            onChange={(e) => setChatInput(e.target.value)}
                        />
                        <Typography variant="caption" color="text.secondary">
                            Hvis du ikke får svarene du trenger så kan du
                            opprett en supportsak
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    );
}

function OverviewViewer(props) {
    const data: IDashboard = useLoaderData() as IDashboard;
    const [chatInput, setChatInput] = useState("");
    const [chatLoading, setChatLoading] = useState(true);
    const [chatHistory, setChatHistory] = useState(null);
    const { userData } = useContext(UserContext);
    const { setShowTicketModal, setActiveTab } = props;

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                const data = await Api.get("/chat");
                setChatHistory(data?.result);
            } catch (e) {}
            setChatLoading(false);
        };
        fetchChatHistory();
    }, []);

    async function sendChatMessage() {
        setChatLoading(true);
        try {
            const data = await Api.post("/chat", { chat_message: chatInput });
            setChatHistory(data.result);
        } catch (e) {
            console.log(e);
        }
        setChatLoading(false);
        setChatInput("");
    }

    async function handleKeyPress(event) {
        if (event.key === "Enter") {
            if (chatInput && chatInput.length > 0) {
                sendChatMessage();
            }
            event.preventDefault();
        }
    }

    async function deleteChatHistory() {
        try {
            await Api.delete("/chat");
            setChatHistory(null);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const element = document.getElementById("chatContainer");
        element.scrollTo(0, element.scrollHeight);
    }, [chatHistory]);

    async function transformChatToBmscase() {
        let quoteHtmlString = chatHistory
            ?.map((msgObj, i) =>
                i > 0
                    ? `<blockquote><b>${msgObj.role === "user" ? "Du skrev" : "IT.no"}: </b> ${msgObj.content}</blockquote>`
                    : "",
            )
            .join("");
        quoteHtmlString = "<p>Fra chatten</p>" + quoteHtmlString + "<p></p>";
        props.setInitBmsHtml(quoteHtmlString);
    }

    return (
        <>
            <div className={style.dashboardWrapperScroll} id="chatContainer">
                <Container>
                    <Box
                        sx={{
                            marginTop: "2rem",
                            height: "90vh",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                        }}
                    >
                        <Box>
                            <Grid container direction={"column"} spacing={8}>
                                <Grid item md={12}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        direction={"column"}
                                        sx={{ height: "100%" }}
                                    >
                                        <Grid item sx={{ textAlign: "center" }}>
                                            <Typography variant={"h4"}>
                                                Velkommen til IT-portalen,{" "}
                                                {userData?.first_name}!
                                            </Typography>
                                            <Typography
                                                variant={"body1"}
                                                sx={{ margin: "1rem 0 1rem 0" }}
                                            >
                                                Få full oversikt over IT-miljøet
                                                ditt
                                            </Typography>
                                            <Box>
                                                <TextField
                                                    id="standard-basic"
                                                    label="Hva kan vi hjelpe deg med?"
                                                    variant="standard"
                                                    sx={{ width: "100%" }}
                                                    onClick={() => {
                                                        setShowTicketModal(
                                                            true,
                                                        );
                                                    }}
                                                    onChange={() =>
                                                        setShowTicketModal(true)
                                                    }
                                                    value={""}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => {}}
                                                                    edge="end"
                                                                >
                                                                    <ArrowForwardIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    helperText="Opprett supportsak eller spør vår AI chatbot" 
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    <Grid
                                        container
                                        justifyContent={"center"}
                                        spacing={4}
                                    >
                                        <Tile
                                            title="Åpne saker"
                                            desc="Dine åpne saker"
                                            value={data?.cases?.length}
                                            action={{
                                                label: "Se mer",
                                                click: () =>
                                                    setActiveTab("support"),
                                            }}
                                        />
                                        <Tile
                                            title="Enheter"
                                            desc="Dine enheter"
                                            value={data?.devices?.length}
                                            action={{
                                                label: "Se mer",
                                                click: () =>
                                                    setActiveTab("device"),
                                            }}
                                        />
                                        <Tile
                                            title="Lisenser"
                                            desc="Dine lisenser"
                                            value={data?.licenses?.length}
                                            action={{
                                                label: "Se mer",
                                                click: () =>
                                                    setActiveTab("license"),
                                            }}
                                        />
                                        <Tile
                                            title="MFA"
                                            desc="Viser mfa status for din bruker"
                                            value={
                                                userData?.mfa_activated ? (
                                                    <IconWithText>
                                                        <CheckCircleOutlineIcon color="success" />
                                                        <Typography>
                                                            Godkjent
                                                        </Typography>
                                                    </IconWithText>
                                                ) : (
                                                    <IconWithText>
                                                        <PriorityHighIcon color="disabled" />
                                                        <Typography>
                                                            Ikke aktivert
                                                        </Typography>
                                                    </IconWithText>
                                                )
                                            }
                                        />
                                    </Grid>{" "}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                position: "relative",
                            }}
                            className={style.chatTextBox}
                        >
                            <div className={style.diagramHeader}>
                                <Typography
                                    variant="h6"
                                    color="text.secondary"
                                    sx={{ padding: "0.5rem 0 0 1rem" }}
                                >
                                    Våre Tjenester
                                </Typography>
                            </div>

                            <Grid
                                container
                                style={{ padding: "1.5rem" }}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Grid item>
                                    <ServiceItem
                                        header="Webshop"
                                        content="Kjøp av utstyr til bedriften"
                                        img={"/static/gfx/icons/webshop_icon.png"}
                                        url={"https://shop.it.no/"}
                                        disabled={
                                            userData?.tenant
                                                .service_links_settings
                                                .disable_webshop_link
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <ServiceItem
                                        header="Nettverksportal"
                                        content="Administrer og overvåk nettverket"
                                        img={"/static/gfx/icons/network_dash_icon.png"}
                                        url={"https://dashboard.it.no/"}
                                        disabled={
                                            userData?.tenant
                                                .service_links_settings
                                                .disable_network_portal_link
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <ServiceItem
                                        header="Intranett"
                                        content="Se siste nytt fra bedriftens intranett"
                                        img={"/static/gfx/icons/intranet_icon.png"}
                                        url={
                                            userData?.tenant
                                                .service_links_settings
                                                .intranet_link
                                        }
                                        disabled={
                                            userData?.tenant
                                                .service_links_settings
                                                .intranet_link === ""
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box
                            sx={{
                                marginTop: "1rem",
                                // backgroundColor:"ButtonFace",
                            }}
                        >
                            <div
                                style={{
                                    textAlign: "center",
                                    marginBottom: "1rem",
                                }}
                            >
                                <Typography variant="h5">
                                    Utforsk IT-miljøet
                                </Typography>
                            </div>
                            <NewsGrid posts={data.posts} />
                        </Box>

                        <Box
                            sx={{
                                flex: 1,
                                display: "none",
                            }}
                        >
                            <Box
                                sx={{
                                    height: "100%",
                                    maxHeight: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                className={style.chatTextBox}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                        position: "relative",
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    <div className={style.diagramHeader}>
                                        <Typography
                                            variant="h6"
                                            color="text.secondary"
                                            sx={{ padding: "0.5rem 0 0 1rem" }}
                                        >
                                            AI-chat
                                        </Typography>
                                        {chatHistory?.length > 0 && (
                                            <Button
                                                startIcon={<DeleteIcon />}
                                                variant="text"
                                                size="small"
                                                onClick={() =>
                                                    deleteChatHistory()
                                                }
                                                sx={{ marginRight: "1rem" }}
                                            >
                                                Slett historikk
                                            </Button>
                                        )}
                                    </div>
                                    <div className={style.chatPlaceholder}>
                                        {(chatHistory === null ||
                                            chatHistory?.length < 2) && (
                                            <>
                                                <SupportAgentIcon
                                                    sx={{
                                                        transform: "scale(2)",
                                                    }}
                                                />
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        marginTop: "1rem",
                                                    }}
                                                >
                                                    Spør vår AI-chat
                                                </Typography>
                                            </>
                                        )}
                                    </div>
                                    <div className={style.chatSequence}>
                                        {chatHistory?.map((chat, index) => {
                                            if (index === 0) return <></>;
                                            return (
                                                <div key={index}>
                                                    <div
                                                        className={
                                                            style.chatAuthor
                                                        }
                                                    >
                                                        <b>
                                                            {chat.role ===
                                                            "user"
                                                                ? "Du skrev"
                                                                : "IT.no"}
                                                        </b>
                                                    </div>
                                                    <Typography variant="body1">
                                                        {chat.content}
                                                    </Typography>
                                                </div>
                                            );
                                        })}
                                        {chatHistory?.length > 1 && (
                                            <div
                                                className={style.chatCreateCase}
                                            >
                                                <Button
                                                    startIcon={<AddIcon />}
                                                    size="small"
                                                    variant="text"
                                                    onClick={
                                                        transformChatToBmscase
                                                    }
                                                >
                                                    Lag supportsak med
                                                    chathistorikk
                                                </Button>
                                                <br />
                                            </div>
                                        )}
                                    </div>
                                </Box>
                                <Box
                                    sx={{
                                        height: "5rem",
                                        paddingLeft: "1rem",
                                        paddingRight: "1rem",
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        sx={{ marginTop: "1rem" }}
                                        disabled={chatLoading}
                                        InputProps={{
                                            endAdornment: chatLoading ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <IconButton
                                                    edge="end"
                                                    color="inherit"
                                                    onClick={sendChatMessage}
                                                >
                                                    <SendIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        label="Skriv her"
                                        variant="outlined"
                                        value={chatInput}
                                        onKeyDown={handleKeyPress}
                                        onChange={(e) =>
                                            setChatInput(e.target.value)
                                        }
                                    />
                                    <Typography
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        Hvis du ikke får svarene du trenger så
                                        kan du opprett en supportsak
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    );
}

function AccountViewer() {
    const { userData } = useContext(UserContext);
    const email = userData?.email_address;

    let url = "https://passwordreset.microsoftonline.com/";
    if (email) {
        url += "?username=" + email;
    }

    return (
        <>
            <Container maxWidth="xl">
                <Grid
                    container
                    sx={{ paddingTop: "2rem" }}
                    justifyContent={"center"}
                >
                    <Grid item md={5}>
                        <Typography variant={"h5"}>
                            Gjenopprette passord
                        </Typography>
                        <div className="separator"></div>
                        <p>
                            Hvis du har glemt passordet til Microsoft kontoen
                            din kan du bruke følgende portal for å sette nytt
                            passord.
                        </p>
                        <p>
                            Underveis i prosessen så vil du bli spurt om å
                            verifisere deg med enten epost, mobilnummer og eller
                            MFA.
                        </p>
                        <Button
                            variant="contained"
                            startIcon={<OpenInNewIcon />}
                            target="_blank"
                            href={url}
                        >
                            Gå til portal
                        </Button>
                        <div className="separator"></div>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default function Dashboard() {
    const data: IDashboard = useLoaderData() as IDashboard;
    const { userData } = useContext(UserContext);
    const [activeCase, setActiveCase] = useState<ITicket>(null);
    const [initBmsHtmlString, setInitBmsHtml] = useState("");
    const [showTicketModal, setShowTicketModal] = useState(false);
    const [activeTab, setActiveTab] = useState("overview");

    useEffect(() => {
        if (data?.cases?.length > 0) {
            setActiveCase(data?.cases[0]);
        }
    }, [data?.cases]);

    function handleCloseBmsModal(ignorePrompt) {
        if (ignorePrompt) {
            setShowTicketModal(false);
            setInitBmsHtml("");
            return;
        }
        let res = window.confirm(
            "Du kommer til å miste innholet. Ønsker du å lukke vinduet?",
        );
        if (res) {
            setShowTicketModal(false);
            setInitBmsHtml("");
        }
    }

    return (
        <>
            <TicketModal
                show={showTicketModal}
                closeModal={handleCloseBmsModal}
                initContentHtml={initBmsHtmlString}
            />
            <div className="pageHeaderWrapper">
                <Typography variant="h6" className="pageHeader">
                    Personlig Dashboard
                </Typography>

                <div className={style.tabsWrapper}>
                    <Tabs
                        value={activeTab}
                        onChange={(e, v) => {
                            setActiveTab(v);
                        }}
                        textColor="inherit"
                        TabIndicatorProps={{
                            style: { backgroundColor: "black" },
                        }}
                    >
                        <Tab label="Oversikt" value={"overview"} />
                        <Tab label="Åpne saker" value={"support"} />
                        <Tab label="Enheter" value={"device"} />
                        <Tab label="Lisenser" value={"license"} />
                        {userData?.tenant?.has_activated_password_reset && (
                            <Tab label="Glemt passord" value={"account"} />
                        )}

                        {/*<Tab label="Chatbot" value={"chat"} />*/}
                    </Tabs>
                </div>
            </div>

            <div className={style.tabContent}>
                {activeTab === "overview" && (
                    <OverviewViewer
                        setShowTicketModal={setShowTicketModal}
                        setActiveTab={setActiveTab}
                        setInitBmsHtml={(html) => {
                            setInitBmsHtml(html);
                            setShowTicketModal(true);
                        }}
                    />
                )}
                {activeTab === "support" && (
                    <TicketViewer
                        results={data?.cases}
                        setResult={() => {}}
                        activeCase={activeCase}
                        setActiveCase={setActiveCase}
                    />
                )}
                {activeTab === "chat" && (
                    <ChatViewer
                        setInitBmsHtml={(html) => {
                            setInitBmsHtml(html);
                            setShowTicketModal(true);
                        }}
                    />
                )}
                {activeTab === "device" && (
                    <DeviceTable result={data.devices} buttonClick={null} />
                )}
                {activeTab === "license" && (
                    <LicenseViewer result={data.licenses} />
                )}
                {activeTab === "account" && <AccountViewer />}
            </div>
        </>
    );
}
